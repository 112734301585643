<template>
  <v-container class="px-6">
    <page-header>{{ $t('inventoryStatus') }}</page-header>
    <div class="mt-4">
      <div class="text-subtitle-1">
        {{ $t('chooseOneRecordToCheck') }}
      </div>
      <horizontal-list class="mt-4">
        <v-card
          :color="activeRecordId===-1?'success darken-4':'green lighten-5'"
          :dark="activeRecordId===-1"
          class="pa-3 d-flex flex-column"
          elevation="0"
          height="72"
          width="144"
          @click="activeRecordId=-1"
        >
          <div>
            {{ $t('inventoryStatusNow') }}
          </div>
          <v-spacer/>
          <div class="text-body-2">
            {{ $t('fromLastCheckToNow') }}
          </div>
        </v-card>
        <v-card
          v-for="r in checkRecords"
          :key="r.id"
          :color="activeRecordId===r.id?'success darken-4':'green lighten-5'"
          :dark="activeRecordId===r.id"
          class="pa-3 d-flex flex-column"
          elevation="0"
          height="72"
          width="144"
          @click="activeRecordId=r.id"
        >
          <div>
            {{ $t('inventoryRecord') }}#{{ r.id }}
          </div>
          <v-spacer/>
          <div class="text-body-2">
            {{ r.currentRecordTime |beautifulTime }}
          </div>
        </v-card>
      </horizontal-list>
    </div>
    <div v-if="!loading">
      <div class="d-flex align-baseline">
        <div>
          <page-sub-header>{{ $t('inventoryDetail') }}</page-sub-header>
          <div class="text-body-2 mt-n6 mb-8">
            {{ activeTimeRange[0] | beautifulTime }} ~ {{ activeTimeRange[1] | beautifulTime }}
          </div>
        </div>

        <v-spacer/>
        <v-btn
          color="success darken-4"
          text
        >
          <v-icon left>
            mdi-file-pdf-box
          </v-icon>
          {{ $t('download') }}
        </v-btn>
      </div>

      <div
        class="mb-4"
        style="display: grid;grid-template-columns: repeat(4,minmax(0,1fr));grid-gap: 8px"
      >
        <v-card
          class="pa-4 d-flex flex-column"
          elevation="0"
        >
          <div class="text-subtitle-2">
            {{ $t('totalCurrent') }}
          </div>
          <div class="text-caption">
            {{ $t('alreadyCheckDifferentCost') }}
          </div>

          <div class="text-h3 font-weight-black mt-4">
            {{ statistic.endOfTermCost | priceDisplay }}
          </div>
        </v-card>
        <v-card
          class="pa-4 d-flex flex-column"
          elevation="0"
        >
          <div class="text-subtitle-2">
            {{ $t('duringChange') }}
          </div>
          <div class="text-caption">
            {{ $t('inTheEarly') }}({{ statistic.beginningOfTermCost | priceDisplay }}) - {{ $t('inTheEnd') }}({{
              statistic.endOfTermCost | priceDisplay
            }})
          </div>

          <div class="text-h3 font-weight-black mt-4">
            {{ statistic.beginningOfTermCost - statistic.endOfTermCost | priceDisplay }}
          </div>
        </v-card>
        <v-card
          class="pa-4 d-flex flex-column"
          elevation="0"
        >
          <div class="text-subtitle-2">
            {{ $t('soldCost') }}
          </div>
          <div class="text-caption">
            {{ $t('totalPriceOfAllRawMaterials(includingLoss)') }}
          </div>

          <div class="text-h3 font-weight-black mt-4">
            {{ statistic.totalOutDishResourceCost * -1 | priceDisplay }}({{ statistic.loss | priceDisplay }})
          </div>
        </v-card>
        <v-card
          class="pa-4 d-flex flex-column"
          elevation="0"
        >
          <div class="text-subtitle-2">
            {{ $t('GP') }}
          </div>
          <div class="text-caption">
            {{ $t('periodTurnover') }}({{ totalSales | priceDisplay }}) - {{ $t('soldCost') }}
          </div>

          <div class="text-h3 font-weight-black mt-4">
            {{ totalSales + statistic.totalOutDishResourceCost | priceDisplay }}
            ({{
              clamp(((totalSales + statistic.totalOutDishResourceCost) * 100 / (totalSales || 1)), 0, 100).toFixed(2)
            }}%)
          </div>
        </v-card>
      </div>
      <div style="position: sticky;top: 0;z-index: 2;background: rgb(250, 251, 252)">
        <div class="mb-4">
          <v-tabs
            v-model="currentTab"
            color="success darken-4"
          >
            <v-tab>{{ $t('inventoryStatus') }}</v-tab>
            <v-tab>{{ $t('totalChange') }}</v-tab>
            <v-tab>{{ $t('changeRecord') }}</v-tab>
          </v-tabs>
          <v-divider/>
        </div>
      </div>
      <div>
        <div>
          <template v-if="currentTab===0">
            <v-card
              v-for="r in resourceCount"
              :key="r.dishResource.id"
              class="pa-4 mb-1 "
              elevation="0"
            >
              <div class="d-flex align-center">
                <div>
                  {{ r.dishResource.name }}
                </div>
                <v-spacer/>
                <div class="d-flex">
                  <div style="width: 120px;text-align: right">
                    {{ r.unitDisplay }}
                  </div>
                </div>
              </div>
              <div class="py-2">
                <div
                  v-for="l in r.groupByPrice"
                  :key="l.price"
                  class="d-flex align-center text-body-2"
                >
                  {{ $t('purchasePrice') }} / {{ l.price | priceDisplay }}
                  <v-spacer/>
                  {{ l.unitDisplay || 0 }}
                </div>
              </div>
            </v-card>
          </template>
          <template v-if="currentTab===1">
            <div
              v-if="recordList.length>0"
              class="pa-1 mt-4"
            >
              <v-card
                v-for="{dishResource,changeLogSummary} in recordList"

                :key="dishResource.id"
                class="pa-4 mb-1 d-flex align-center"
                elevation="0"
              >
                <div>
                  {{ dishResource.dishResource.name }}
                </div>
                <v-spacer/>
                <div class="d-flex mr-12">
                  <v-icon>
                    mdi-cash-multiple
                  </v-icon>
                  <div style="width: 96px;text-align: right">
                    {{ changeLogSummary.totalCost | priceDisplay }}
                  </div>
                </div>
                <div class="d-flex">
                  <v-icon
                    v-if="changeLogSummary.amount<0"
                    color="warning"
                  >
                    mdi-trending-down
                  </v-icon>
                  <v-icon
                    v-if="changeLogSummary.amount>=0"
                    color="success"
                  >
                    mdi-trending-up
                  </v-icon>
                  <div style="width: 72px;text-align: right">
                    {{ changeLogSummary.unitDisplay }}
                  </div>
                </div>
              </v-card>
            </div>
            <div v-else>
              <div
                class="d-flex align-center justify-center flex-column"
                style="height: 400px;width: 100%"
              >
                <v-icon size="64">
                  mdi-tea
                </v-icon>
                <div>
                  {{ $t('thisCheckHaveNotChange') }}
                </div>
              </div>
            </div>
          </template>
          <template v-if="currentTab===2">
            <div
              v-if="detailedLog.length>0"
              class="pa-1 mt-4"
            >
              <v-card
                v-for="{changeLog,dishResource} in detailedLog"
                :key="changeLog.id"
                class="pa-4 mb-1 d-flex align-center"
                elevation="0"
              >
                <div style="width: 96px">
                  <div class="text-caption">
                    {{ changeLog.createdAt | beautifulTime }}
                  </div>
                  <v-chip
                    :color="changeLog.operation==='Enter'?'success darken-4':'error darken-4'"
                    label
                    small
                  >
                    {{ changeLog.operation }}
                  </v-chip>
                </div>
                <div>
                  <div class="text-body-1 font-weight-bold">
                    {{ dishResource.dishResource.name }}
                  </div>
                  <div class="text-body-2">
                    {{ changeLog.note || $t('withoutNote') }}
                  </div>
                </div>
                <v-spacer/>

                <div>
                  <div class="d-flex">
                    <v-icon
                      v-if="changeLog.amount<0"
                      color="warning"
                    >
                      mdi-trending-down
                    </v-icon>
                    <v-icon
                      v-if="changeLog.amount>=0"
                      color="success"
                    >
                      mdi-trending-up
                    </v-icon>
                    <div style="width: 96px;text-align: right">
                      {{ changeLog.unitDisplay }}
                    </div>
                  </div>
                  <div class="d-flex text-body-2">
                    <v-icon>
                      mdi-cash-multiple
                    </v-icon>
                    <div style="width: 96px;text-align: right">
                      {{ changeLog.costLiteral | priceDisplay }}
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
            <div v-else>
              <div
                class="d-flex align-center justify-center flex-column"
                style="height: 400px;width: 100%"
              >
                <v-icon size="64">
                  mdi-tea
                </v-icon>
                <div>
                  {{ $t('thisCheckHaveNotChange') }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex align-center justify-center"
      style="height: 400px"
    >
      <v-progress-circular
        indeterminate
        size="48"
      />
    </div>
  </v-container>
</template>

<script>
  import PageHeader from '@/composable/InK/typography/PageHeader.vue'
  import { clamp } from 'lodash-es'
  import { getClearingSummary, getClearList, getInOutLog, getResourceCount, getStatistic } from '@/model/storage/inventory'
  import HorizontalList from '@/composable/InK/HorizontalList.vue'
  import PageSubHeader from '@/composable/InK/typography/PageSubHeader.vue'
  import dayjs from 'dayjs'
  import DashboardCard from '@/views/dashboard/components/DashboardCard.vue'
  import { previewZBonByTimeSpan } from '@/common/Utlis/umsatzInfo'

  const ktorTimeFormat = 'YYYY-MM-DDTHH:mm:ss'

  export default {
    name: 'InventoryPage',
    components: {
      DashboardCard,
      PageSubHeader,
      HorizontalList,
      PageHeader,
    },
    data () {
      return {
        checkRecords: [],
        activeRecordId: null,
        recordList: [],
        resourceCount: [],
        detailedLog: [],
        currentTab: 0,
        statistic: {
          totalCost: 0,
          totalEnterDishResourceCost: 0,
          totalOutDishResourceCost: 0,
          beginningOfTermCost: 0,
          endOfTermCost: 0,
          loss: 0,
        },
        totalSales: 0,
        loading: false,
      }
    },
    computed: {
      activeTimeRange () {
        const record = this.checkRecords.find(it => it.id === this.activeRecordId)
        if (record) {
          return [record.previousRecordTime, record.currentRecordTime]
        } else {
          return [
            this.checkRecords?.[0]?.currentRecordTime ??
              dayjs().subtract(1, 'd').format(ktorTimeFormat),
            dayjs().format(ktorTimeFormat),
          ]
        }
      },
    },
    watch: {
      activeRecordId () {
        this.refreshData()
      },
    },
    async mounted () {
      await this.refreshCheckRecord()
    },
    methods: {
      clamp,
      async refreshCheckRecord () {
        this.checkRecords = await getClearList()
        this.activeRecordId = -1
      },
      async refreshData () {
        this.loading = true
        await this.refreshSummaries()
        await this.refreshResource()
        this.detailedLog = await getInOutLog(...this.activeTimeRange)
        this.statistic = await getStatistic(...this.activeTimeRange)
        this.totalSales = (await previewZBonByTimeSpan(...this.activeTimeRange.map(it => {
          return dayjs(it).format('YYYY-MM-DD HH:mm:ss')
        }))).content.total
        this.loading = false
      },
      async refreshResource () {
        this.resourceCount = await getResourceCount(this.activeRecordId)
        console.log(this.resourceCount)
      },
      async refreshSummaries () {
        this.recordList = await getClearingSummary(...this.activeTimeRange)
      },
    },
  }
</script>

<style scoped>

</style>
